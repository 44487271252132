import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";

export default function App() {
  const [posts, setPosts] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchPost = async () => {
    setLoading(true);
    try {
      const response = await axios("https://story.visualinfinity.asia:8000/summary-random-story");
      setPosts(response.data);
    } catch (error) {
      setPosts("Tải truyện không thành công. Vui lòng thử lại!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPost();
  }, []);

  return (
    <div className="App d-flex flex-column align-items-center justify-content-center min-vh-100" style={{maxWidth:800, margin:"0 auto"}}>
      <h2 className="text-center mb-4 mt-4">Nội dung truyện</h2>
      <div className="card p-3 shadow-sm w-75">
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <p className="text-right">
            {posts.split("\n\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </p>
        )}
      </div>
      <button
        className="btn btn-primary mt-4 mb-4"
        onClick={fetchPost}
        disabled={loading}
      >
        {loading ? "Loading..." : "Tải truyện khác"}
      </button>
    </div>
  );
}
